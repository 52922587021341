.profile-container {
    padding: 20px;
    background-color: #ffffff; 
  }
  
  .profile-recipes {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .recipe-cards {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 20px;
  }
  
  .recipe-card {
    border: 1px solid #6a5acd; 
    border-radius: 8px;
    padding: 10px;
    transition: transform 0.3s ease-in-out;
    cursor: pointer;
    text-decoration: none;
}
.profileIngredients{
    font-size:small;
    background-color: #6a5acd;
    margin-right: 10px;
    padding: 8px;
    color: white;
    border-radius: 5px;
    margin-top: 10px;
}
  
  .recipe-card:hover {
    transform: scale(1.02);
  }
  
  h3 {
    color: #6a5acd; 
  }
  
  ul {
    list-style: none;
    padding: 0;
  }
  
  li {
    margin-bottom: 5px;
  }
  
  
