/* FloatingButton.css */
.floating-button {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background-color: #6a5acd;
    color: #fff;
    border: none;
    border-radius: 25px;
    width: 50px;
    height: 50px;
    font-size: 24px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
  }
  
  .floating-button:hover {
    width: 150px;
  }
  
  .floating-button .icon {
    transition: all 0.3s ease-in-out;
  }
  
  .floating-button:hover .icon {
    transform: translateX(-10px);
  }
  
  .floating-button .text {
    display: none;
    margin-left: 8px;
    white-space: nowrap;
    transition: all 0.3s ease-in-out;
    font-size: x-small;
  }
  
  .floating-button:hover .text {
    display: inline;
    opacity: 1;
  }
  