/* NewRecipe.css */
.new-recipe {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    text-align: center;
    p{
      text-align: start;
      font-size: large;
      margin-top: 30px;
    }
  }
  
  .ingredient-card {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: move;
    transition: background-color 0.3s;
  }
  
  .ingredient-card.dragging {
    background-color: #f8f8f8;
  }
  
  .ingredient-content {
    display: flex;
    align-items: center;
    width: 100%;
  }
  
  .ingredient-content .drag-icon {
    cursor: grab;
    margin-right: 10px;
  }
  
  .ingredient-content input {
    flex: 1;
    margin-right: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .ingredient-content .remove-icon {
    cursor: pointer;
  }
/* Add the following styles to your CSS file or style section */

/* Existing styles... */

.recipeHeader {
  font-size: 24px;
  margin-bottom: 20px;
}

.recipeNameInput {
  width: -webkit-fill-available;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 18px;
  margin-bottom: 10px;
}

/* Add the following styles to your CSS file or style section */

/* Add the following styles to your CSS file or style section */

.addButton {
  background-color: #6a5acd;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;
  height: 50px;
}

.addButton:hover {
  background-color: #5500b7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}
/* Add the following styles to your CSS file or style section */

/* Existing styles... */

.recipeTextarea {
  width: -webkit-fill-available;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  resize: vertical; /* Allow vertical resizing */
  min-height: 100px;
}
.descArea{
  width: -webkit-fill-available;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 20px;
  resize: vertical; /* Allow vertical resizing */
}

.submitButton {
  background-color: #6a5acd;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  padding: 10px;
  font-size: 18px;
  cursor: pointer;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s, box-shadow 0.3s;
  width: 100%;
}

.submitButton:hover {
  background-color: #5500b7;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

/* Add the following styles to your CSS file or style section */
